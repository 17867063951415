import React, {useCallback, useEffect, useRef} from 'react';
import StatesMapSVG from "@/components/common/Icon/SVGR/StatesMap";
import {useBranch} from "@/hooks/useBranch";
import styled from "styled-components";
import {isFunction} from "@/utils/utils";
import useColor from "@/hooks/useColor";
import {GLOBAL_STATE} from "@/constants";

const StyledStatesMapSVG = styled(StatesMapSVG)`
	--active-color: ${({theme, $activeColor}) => $activeColor || theme.colors.periwinkle};
	max-width: unset;
	width: 100%;
	g{
        path {
            fill: ${({theme}) => theme.colors.white};
            cursor: pointer;
            pointer-events: auto;
            transition: fill var(--rgr-duration) var(--ease), opacity var(--rgr-duration) var(--ease);
			
			

            @media (hover: hover) {
                &:hover{
                    fill: var(--active-color);
                }
            }
        }
		
		path.region-capital, circle{
			pointer-events: none;
		}
		
        &.active{
            path{
                fill: var(--active-color);
            }
        }
	}
`

const StatesMap = ({ onBranchClick = (e) => {}, activeColor="periwinkle" }) => {
	const MapRef = useRef()
	const { selectedOption, setSelectedOption } = useBranch()
	const aColor = useColor(activeColor)

	const handleStateClick = useCallback((e) => {
		const group = e?.target?.parentNode
		if(!group) return
		let updatedState = group.id
		if(selectedOption === group.id) updatedState = GLOBAL_STATE
		setSelectedOption(updatedState)
		if(isFunction(onBranchClick)){
			onBranchClick(e)
		}
	},[selectedOption, setSelectedOption])

	useEffect(() => {
		if(selectedOption === GLOBAL_STATE) return

		const targetStatePath = MapRef.current.querySelector(`#${selectedOption}`)
		if(!targetStatePath) return

		targetStatePath.classList.add('active')

		return () => {
			targetStatePath.classList.remove('active')
		}
	},[selectedOption])

	return (
		<StyledStatesMapSVG
			ref={MapRef}
			onStateClick={handleStateClick}
			$activeColor={aColor}
		/>
	);
};

export default StatesMap;
