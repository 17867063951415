import React, {useCallback, useEffect, useRef} from 'react';
import SimpleMapSVG from "@/components/common/Icon/SVGR/SimpleMap";
import styled from "styled-components";
import {useBranch} from "@/hooks/useBranch";
import useColor from "@/hooks/useColor";
import {isFunction} from "@/utils/utils";
import {GLOBAL_STATE} from "@/constants";


const Map = styled(SimpleMapSVG)`
    --active-color: ${({theme, $activeColor}) => $activeColor || theme.colors.periwinkle};
	max-width: unset;
	//height: 100%;
	path {
		fill: ${({theme}) => theme.colors.white};
		cursor: pointer;
		pointer-events: auto;
        transition: fill var(--rgr-duration) var(--ease), opacity var(--rgr-duration) var(--ease);
		
	}
    &.active,  &:hover{
		path{
            fill: var(--active-color);
		}
    }
`
const SimpleMap = ({ onBranchClick = (e) => {}, activeColor="periwinkle" }) => {
	const MapRef = useRef()
	const { selectedOption, setSelectedOption } = useBranch()
	const aColor = useColor(activeColor)

	const handleClick = useCallback((e) => {
		setSelectedOption(GLOBAL_STATE)
		if(isFunction(onBranchClick)){
			onBranchClick(e)
		}
	},[setSelectedOption])

	useEffect(() => {
		if(selectedOption !== GLOBAL_STATE) {
			MapRef.current.classList.remove('active')

		} else {
			MapRef.current.classList.add('active')

		}
	},[selectedOption])

	return (
		<Map
			ref={MapRef}
			onClick={handleClick}
			$activeColor={aColor}
		/>
	);
};

export default SimpleMap;
