import React from 'react';
import StatesMap from "@/components/Maps/StatesMap";
import styled from "styled-components";
import {BREAKPOINTS} from "@/styles/themeSettings";
import SimpleMap from "@/components/Maps/SimpleMap";

const Wrapper = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	height: auto;
	
	#simple-map{
		position: absolute;
		left: 0;
		bottom: 0;
        width: 20%;
		aspect-ratio: 1;
	}
	
	&.simple-map-right {
		#states-map {
			translate: -3% 0;
		}
		
        #simple-map{
            position: absolute;
			left: unset;
            right: -14%;
            bottom: 0;
            width: 20%;
            aspect-ratio: 1;
        }
    }
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        #simple-map{
            width: 18%;
        }
    }
`

const BothMaps = ({ className = ''}) => {
	return (
		<Wrapper className={`both-maps-wrapper ${className}`}>
			<StatesMap />
			<SimpleMap />
		</Wrapper>
	);
};

export default BothMaps;
